.p {
  width: 30%;
  height: 40vh;
  min-height: 280px;
  margin: 20px 10px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
}

.p-browser {
  height: 20px;
  background-color: rgb(181 169 169);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.p-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: white;
}

.p-title {
  margin-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}

.p-img {
  width: 100%;
  transition: all 10s ease;
  min-height: 280px;
  object-fit: contain;
}

.p:hover .p-img {
  transform: translateY(-100%);
}

@media screen and (max-width: 1024px) {
  .p {
    width: 45%;
    height: 20vh;
    min-height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .p-img {
    min-height: 250px;
  }
}

@media screen and (max-width: 680px) {
  .p {
    width: 80%;
    height: 20vh;
    min-height: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .p-img {
    min-height: 200px;
  }
}
