.a {
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.a-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
}

.a-right {
  flex: 1;
  overflow-x: hidden;
  width: 100%;
}

.a-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.a-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
}

.a-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a-award-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  margin-right: 10px;
}

.a-title {
  font-weight: 400;
  font-size: 2em;
}

.a-sub {
  margin: 20px 0px;
}

.a-desc {
  font-weight: 300;
  margin: 20px 0;
}

.a-award {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-award-texts {
  width: 70%;
}

.a-award-title {
  font-weight: bold;
  /* color: #555; */
  margin-bottom: 10px;
  text-align: left;
}

.a-award-title > a {
  text-decoration: underline;
  color: inherit;
}

.a-award-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.a-review-date {
  color: #555;
  font-size: 12px;
  font-weight: 300;
  float: right;
  margin-right: 20px;
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .a {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }

  .a-left {
    width: 100%;
  }
  .a-right {
    width: 100%;
  }

  .a-card {
    height: 30vh;
  }

  .a-card.bg,
  .a-award {
    display: none;
  }

  .a-right {
    padding: 20px;
  }

  .a-award-img {
    width: 100px;
    height: 100px;
  }
}

@keyframes display {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  2% {
    transform: translateX(0);
    opacity: 1;
  }
  13% {
    transform: translateX(0);
    opacity: 1;
  }
  15% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}

.pic-ctn {
  position: relative;
  width: 100%;
  height: 120px;
}

.pic-ctn > .a-award {
  position: absolute;
  width: 100%;
  opacity: 0;
  animation: display 40s ease-in-out infinite;
}

.a-award:nth-child(2) {
  animation-delay: 8s;
}
.a-award:nth-child(3) {
  animation-delay: 16s;
}
.a-award:nth-child(4) {
  animation-delay: 24s;
}
.a-award:nth-child(5) {
  animation-delay: 32s;
}
